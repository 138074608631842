<template>
  <div></div>
</template>


<script>
import { defaultLocale, availableLocales } from '@/i18n';


// This component handles Firebase auth actions, takes care of redirecting to the correct route and locale

export default {
  props: {
    actionCode: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: defaultLocale,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  created() {
    // Set new password
    if (this.mode === 'resetPassword' && this.actionCode) {
      this.$router.replace({
        name: 'SetNewPassword',
        params: {
          locale: availableLocales.includes(this.locale) ? this.locale : defaultLocale,
        },
        query: {
          actionCode: this.actionCode,
        },
      });
    } else {
      console.warn('This auth action mode is not handled. Navigating to SignIn view.');
      this.$router.replace({
        name: 'SignIn',
      });
    }
  },
};
</script>
